import vi from './vi.json';
import no from './no.json';
import en from './en.json';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        resources: {
            vi: {
                translation: vi
            },
            no: {
                translation: no
            },
            en: {
                translation: en
            }
        },
        lng: 'en', // Default language
        keySeparator: false, // Use dot notation in keys
        interpolation: {
            escapeValue: false // React already safe from XSS
        }
    });

export default i18n;
